import { useSession } from "next-auth/react";
import { useEffect } from "react";
import axios from "axios";
import { useUI } from "../context/UIContext";

export const useStartVerifyEmail = () => {
  const { data: session, status }: { data: any; status: string } = useSession();
  const { state: uiState, dispatch } = useUI();

  useEffect(() => {
    if (
      status === "authenticated" &&
      session.accessToken &&
      !uiState.isEmailVerified
    ) {
      window.localStorage.setItem("verify_email_flow", "true");
      const fetchData = async () => {
        const { data } = await axios.get("/api/tm-auth/check-email", {
          params: {
            access_token: session.accessToken,
          },
        });
        if (data?.data?.me?.profile?.emailConfirmed) {
          dispatch({
            type: "confirmEmail",
          });
          window.localStorage.setItem("verify_email_flow", "false");
        }
        if (!data?.data?.me?.profile?.emailConfirmed) {
          dispatch({
            type: "toggleJoinFlowInfoModal",
            payload: { isOpen: true, modalType: "email" },
          });
        }
      };
      fetchData();
    }
  }, [status, uiState.isEmailVerified]);
};
