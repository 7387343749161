import { useEffect, useState } from "react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import CloseIcon from "@mui/icons-material/Close";
import { useUI } from "../../context/UIContext";
import styles from "./JoinFlowInfoModal.module.css";
import { signIn } from "next-auth/react";
import { useRouter } from "next/router";
import Cta from "../Cta/Cta";

interface JoinFlowInfoModalProps {
  isOpen: boolean;
  tmToken: string;
  idTokenHint: string;
}

const JoinFlowInfoModal = ({
  isOpen,
  tmToken,
  idTokenHint,
}: JoinFlowInfoModalProps) => {
  const { state: uiState, dispatch } = useUI();
  const [checkIfEmailVerified, setCheckIfEmailVerified] = useState(false);
  const [verifyEmailFlow, setVerifyEmailFlow] = useState<string | null>("");
  const [ygbActive, setYgbActive] = useState<string | null>("");

  const router = useRouter();

  useEffect(() => {
    if (verifyEmailFlow !== window.localStorage.getItem("verify_email_flow")) {
      setVerifyEmailFlow(window.localStorage.getItem("verify_email_flow"));
    }
    if (ygbActive !== window.localStorage.getItem("ygb_active")) {
      setYgbActive(window.localStorage.getItem("ygb_active"));
    }
  }, [uiState]);

  useEffect(() => {
    if (uiState.joinFlowInfoModal.isOpen) {
      document.body.style.overflow = "hidden";
      document.body.style.position = "fixed";
      document.body.style.touchAction = "none";
      document.body.style.width = "100%";
    } else {
      document.body.style.overflow = "auto";
      document.body.style.position = "static";
      document.body.style.touchAction = "";
      document.body.style.width = "unset";
    }
  }, [isOpen]);

  const { data, refetch: sendVerifyEmailRequest } = useQuery({
    queryKey: ["verify_email"],
    queryFn: () => {
      return axios.get("/api/tm-auth/verify-email", {
        params: {
          access_token: tmToken,
        },
      });
    },
    enabled: false,
    onSuccess: () => {
      setCheckIfEmailVerified(true);
    },
  });

  useQuery({
    queryKey: ["check_verified_email"],
    queryFn: () => {
      return axios.get("/api/tm-auth/check-email", {
        params: {
          access_token: tmToken,
        },
      });
    },
    enabled: checkIfEmailVerified,
    refetchInterval: checkIfEmailVerified ? 5000 : false,
    onSuccess: (aData) => {
      if (aData.data.data.me.profile.emailConfirmed) {
        setCheckIfEmailVerified(false);
        dispatch({
          type: "confirmEmail",
        });
        dispatch({
          type: "openSignupModal",
        });
      }
    },
  });

  if (!isOpen) return null;

  const origin =
    typeof window !== "undefined" && window.location.origin
      ? window.location.origin
      : "";

  return (
    <div className={styles.joinFlowInfoModal}>
      <div className={styles.joinFlowInfoModalContentWrapper}>
        {(uiState.joinFlowInfoModal.modalType === "signup" ||
          (uiState.joinFlowInfoModal.modalType === "email" && !data)) && (
          <CloseIcon
            className={styles.closeIcon}
            style={{
              fontSize: 26,
            }}
            onClick={() => {
              if (verifyEmailFlow === "true") {
                window.localStorage.setItem("verify_email_flow", "false");
              }
              if (ygbActive === "true") {
                window.localStorage.setItem("ygb_active", "false");
              }
              window.localStorage.setItem("next_signup_uri", "");
              if (
                uiState.joinFlowInfoModal.modalType === "email" &&
                !!tmToken
              ) {
                router.push(
                  `https://login.trackmanrange.com/connect/endsession?id_token_hint=${idTokenHint}&post_logout_redirect_uri=${origin}`
                );
              }
              dispatch({
                type: "toggleJoinFlowInfoModal",
                payload: { isOpen: false },
              });
            }}
          />
        )}
        <div className={styles.joinFlowInfoModalContent}>
          {uiState.joinFlowInfoModal.modalType === "signup" && (
            <>
              {uiState.joinFlowInfoModal.title && (
                <h2>{uiState.joinFlowInfoModal.title}</h2>
              )}
              <p>
                To join the tournament, please log in with your Trackman account
                or sign up for a new one.
              </p>
              <Cta
                className={styles.cta}
                name="Go to login"
                onClick={() => {
                  window.localStorage.setItem("ygb_active", "true");
                  signIn("trackman");
                }}
              />
            </>
          )}
          {uiState.joinFlowInfoModal.modalType === "email" && !data && (
            <>
              <p>
                To sign up for the tournament, you must first verify your email
                address.
              </p>
              <Cta
                className={styles.cta}
                name="Verify email"
                onClick={() => sendVerifyEmailRequest()}
              />
            </>
          )}
          {uiState.joinFlowInfoModal.modalType === "email" &&
            data?.status === 200 && (
              <>
                <p>
                  Verification email sent to
                  {`${
                    data?.data?.data?.me?.profile?.sendEmailConfirmation?.email
                      ? " " +
                        data.data.data.me.profile.sendEmailConfirmation.email
                      : " your trackman email address"
                  }`}
                  . Check your inbox and follow the instructions.
                </p>
                <p>
                  Once confirmed, you'll be redirected to the next step. To stop
                  sign up, click the button below.
                </p>
                <Cta
                  className={styles.cta}
                  name="Close"
                  onClick={() => {
                    window.localStorage.setItem("verify_email_flow", "false");
                    window.localStorage.setItem("ygb_active", "false");
                    window.localStorage.setItem("next_signup_uri", "");
                    dispatch({
                      type: "toggleJoinFlowInfoModal",
                      payload: { isOpen: false },
                    });
                    router.push(
                      `https://login.trackmanrange.com/connect/endsession?id_token_hint=${idTokenHint}&post_logout_redirect_uri=${origin}`
                    );
                  }}
                />
              </>
            )}
          {uiState.joinFlowInfoModal.modalType === "email" &&
            data &&
            data.status !== 200 && (
              <>
                <p>Ooops... something went wrong. Please try again</p>
                <Cta
                  className={styles.cta}
                  name="Close"
                  onClick={() => {
                    window.localStorage.setItem("verify_email_flow", "false");
                    window.localStorage.setItem("ygb_active", "false");
                    window.localStorage.setItem("next_signup_uri", "");
                    dispatch({
                      type: "toggleJoinFlowInfoModal",
                      payload: { isOpen: false },
                    });
                  }}
                />
              </>
            )}
        </div>
      </div>
    </div>
  );
};

export default JoinFlowInfoModal;
