import { useSession } from "next-auth/react";
import { useEffect } from "react";
import { useUI } from "../context/UIContext";

export const useStartSignup = () => {
  const { data: session, status }: { data: any; status: string } = useSession();
  const { state: uiState, dispatch } = useUI();

  useEffect(() => {
    if (
      status === "authenticated" &&
      session.accessToken &&
      uiState.isEmailVerified
    ) {
      dispatch({
        type: "openSignupModal",
      });
    }
  }, [status, uiState.isEmailVerified]);
};
