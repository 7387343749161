import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import styles from "./SignupModal.module.css";
import Cta from "../Cta/Cta";

interface SignupModalProps {
  isOpen: boolean;
  tmToken: string;
  idTokenHint: string;
}

const SignupModal = ({ isOpen, tmToken, idTokenHint }: SignupModalProps) => {
  const [paymentComplete, setPaymentComplete] = useState(false);
  const [nextSignupUri, setNextSignupUri] = useState<string | null>("");
  const router = useRouter();

  const origin =
    typeof window !== "undefined" && window.location.origin
      ? window.location.origin
      : "";

  useEffect(() => {
    if (nextSignupUri !== window.localStorage.getItem("next_signup_uri")) {
      setNextSignupUri(window.localStorage.getItem("next_signup_uri"));
    }
  }, []);

  useEffect(() => {
    const ygbEventListener = (args: any) => {
      if (args.origin === "https://booking.trackmangolf.com") {
        if (args.data.eventType.toLowerCase() === "purchasemade") {
          setPaymentComplete(true);
        }
      }
    };

    window.addEventListener("message", ygbEventListener);

    return () => {
      window.removeEventListener("message", ygbEventListener);
    };
  }, [idTokenHint]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
      document.body.style.position = "fixed";
      document.body.style.touchAction = "none";
    } else {
      document.body.style.overflow = "auto";
      document.body.style.position = "static";
      document.body.style.touchAction = "";
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className={styles.signupModal}>
      <div className={styles.signupModalNav}>
        <Cta
          name="CLOSE"
          onClick={() => {
            window.localStorage.setItem("verify_email_flow", "false");
            window.localStorage.setItem("ygb_active", "false");
            window.localStorage.setItem("next_signup_uri", "");
            router.push(
              `https://login.trackmanrange.com/connect/endsession?id_token_hint=${idTokenHint}&post_logout_redirect_uri=${origin}`
            );
          }}
          className={styles.backButton}
        />
      </div>
      {!paymentComplete && (
        <iframe
          width="100%"
          height="100%"
          src={`${nextSignupUri}?tm-token=${tmToken}&embedded=true&hideBookingNav=true&autoProceed=true&hideCheckoutHeader=true`}
        />
      )}
      {paymentComplete && (
        <div className={styles.successWrapper}>
          <div className={styles.successContent}>
            <h1>Welcome to the NEXT Golf Tour!</h1>
            <p>Your spot in the upcoming round is now confirmed.</p>
            <p>
              For all the details you need to ace your game, please review the{" "}
              <a
                href="https://www.nextgolftour.com/players-guide"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>Players guide</strong>
              </a>
              —it's packed with essential information and pro tips.
            </p>
            <p>
              Need a hand? Our Help Center is just a click away.{" "}
              <a
                href="https://trackmangolf.zendesk.com/hc/en-us/categories/11965023457179-Tournaments"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>Visit help center</strong>
              </a>
            </p>
            <p>
              Tee off with confidence—we're eager to see you showcase your
              skills!
            </p>
            <p>
              <strong>— The NEXT Golf Tour Team</strong>
            </p>
            <Cta
              name="Back to home page"
              onClick={() => {
                window.localStorage.setItem("verify_email_flow", "false");
                window.localStorage.setItem("ygb_active", "false");
                window.localStorage.setItem("next_signup_uri", "");
                router.push(
                  `https://login.trackmanrange.com/connect/endsession?id_token_hint=${idTokenHint}&post_logout_redirect_uri=${origin}`
                );
              }}
              className={styles.backButton}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SignupModal;
