import { useEffect } from "react";
import { signOut, useSession } from "next-auth/react";
import { useUI } from "../context/UIContext";

export const useTmSignOut = () => {
  const { data: session }: { data: any } = useSession();
  const { state: uiState } = useUI();

  useEffect(() => {
    if (
      session?.accessToken &&
      window.localStorage.getItem("ygb_active") === "false" &&
      window.localStorage.getItem("verify_email_flow") === "false"
    ) {
      signOut({ redirect: false });
    }
  }, [session, uiState]);
};
